@use 'libs/core/src/lib/styles/themes';

#top-tags-filters {
  .filtered-items-options {
    @include themes.themify {
      .options-list {
        display: flex;
        flex-wrap: wrap;
        gap: themes.themed('spacing', 'L');
        padding-top: themes.themed('spacing', 'XS');
      }
    }
  }
}
