@use 'libs/core/src/lib/styles/themes';

.advanced-filters-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}

// Same Advanced Filters drawer width as Panel UI
// Override Panel UI Advanced Filters height and position
.advanced-filters-overlay-responsive {
  $filters-drawer-width: 358px;

  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  position: absolute !important;
  top: 0;

  transition: left 0.3s ease-in-out;
  width: 100%;
  z-index: 100;

  &.open {
    left: 0;
  }


  @include themes.themify() {
    .advanced-filters-header {
      padding: themes.themed('spacing', 'M');
    }
    box-shadow: themes.themed('colors', 'box-shadow');
  }
}
