@use 'libs/core/src/lib/styles/themes';

.smar-asset-details {
  @include themes.themify {
    &.page-section {
      border-top: none !important;
      padding-top: 0 !important;
      margin-top: -8px !important;
    }
  }
}
