@use 'common' as *;
@use 'base-theme-funcs' as *;

// FONT FAMILIES
$smar-base-var-font-family: 'Inter var', 'Arial', 'Helvetica', sans-serif, 'Segoe UI Emoji', 'Segoe UI Symbol';
$smar-base-font-family: 'Inter', 'Arial', 'Helvetica', sans-serif, 'Segoe UI Emoji', 'Segoe UI Symbol';
$smar-medium-font-family: 'Inter-Medium', 'Arial', 'Helvetica', sans-serif, 'Segoe UI Emoji', 'Segoe UI Symbol';
$smar-semi-bold-font-family: 'Inter-Semi-Bold', 'Arial', 'Helvetica', sans-serif, 'Segoe UI Emoji', 'Segoe UI Symbol';
$smar-bold-font-family: 'Inter-Bold', 'Arial', 'Helvetica', sans-serif, 'Segoe UI Emoji', 'Segoe UI Symbol';
$smar-headline-font-family: 'TT Norms Pro Bold', sans-serif;

// COLORS
$primary: #3576ba;

// BUTTONS
$button-primary: $primary;
$button-primary-hover: #6097d2;
$button-primary-pressed: #4c649d;
$button-primary-focus: #3576ba;
$button-primary-disabled: #b3ccea;

$button-secondary: #eeeeee;
$button-secondary-hover: #e0e0e0;
$button-secondary-pressed: #c0c0c0;
$button-secondary-focus: #eeeeee;
$button-secondary-disabled: #f2f2f2;
$button-secondary-active: #b3ccea;

$cta-share: #007e84;
$cta-share-hover: #40bac0;
$cta-share-active: #01595d;
$cta-share-focus: #007e84;
$cta-share-disabled: #aed5d6;

$button-destructive: #cc2e3b;
$button-destructive-hover: #d8656f;
$button-destructive-pressed: #a62833;
$button-destructive-focus: #cc2e3b;
$button-destructive-disabled: #edbec2;

$button-upgrade: #007a49;
$button-upgrade-hover: #40c28f;
$button-upgrade-pressed: #00693f;
$button-upgrade-focus: #007a49;
$button-upgrade-disabled: #b3d7c8;

// ANCHOR LINK
$cta-link: #0970be;

// FOCUS
$focus: #6097d2;
$focus-dark: #4c649d;
$focus-light-10: #b3ccea;
$focus-light-20: #c5d7ed;

// ALERTS
$success: #01af6a;
$success-dark-30: #00462a;
$success-dark-20: #00693f;
$success-dark-10: #008c54;
$success-light-10: #40c28f;
$success-light-20: #7dd4b1;
$success-light-30: #bbe7d6;

$warning: #ffc33e;
$warning-dark-30: #664e18;
$warning-dark-20: #997525;
$warning-dark-10: #cc9c31;
$warning-light-10: #fdd16e;
$warning-light-20: #fcde9b;
$warning-light-30: #fbeccb;

$danger: #d03340;
$danger-dark-30: #531419;
$danger-dark-20: #7c1e26;
$danger-dark-10: #a62833;
$danger-light-10: #db656f;
$danger-light-20: #e4969c;
$danger-light-30: #efc8cb;

$info: #9eb7ce;
$info-dark-30: #3f4952;
$info-dark-20: #5e6d7b;
$info-dark-10: #7e92a4;
$info-light-10: #b6c8da;
$info-light-20: #cbd8e3;
$info-light-30: #e3e9ef;

// NEUTRALS - primarily used for fonts and UI elements
$white: #ffffff;
$light-30: #f2f2f2;
$light-20: #ebebeb;
$light-10: #e0e0e0;
$neutral: #c0c0c0;
$dark-10: #979797; // lightest accessible grey
$dark-20: #727272;
$dark-30: #444444;
$dark-40: #111111;
$blue-light-20: #d8e4fa;

// BACKGROUND COLORS
$default-background: #f8f7f5;
$blanket-background: rgba(0, 0, 0, 0.1);
$left-nav-background: #003059;
$tooltip-background: rgba(84, 84, 84, 1);

$theme: (
  base-typography:
    createTypographyProps(
      $color: $dark-40,
      $font-family: $smar-base-var-font-family,
      $font-size: 13px,
      $font-style: normal,
      $font-weight: 400,
      $line-height: 23px,
    ),
  header-1:
    createTypographyProps(
      $color: $dark-40,
      $font-family: $smar-headline-font-family,
      $font-size: 24px,
      $font-style: normal,
      $font-weight: normal,
      $line-height: 28px,
    ),
  header-2:
    createTypographyProps(
      $color: $dark-40,
      $font-family: $smar-headline-font-family,
      $font-size: 18px,
      $font-style: normal,
      $font-weight: normal,
      $line-height: 24px,
    ),
  header-3:
    createTypographyProps(
      $color: $dark-40,
      $font-family: $smar-semi-bold-font-family,
      $font-size: 15px,
      $font-style: normal,
      $font-weight: 600,
      $line-height: 18px,
    ),
  header-6:
    createTypographyProps(
      $color: $dark-40,
      $font-family: $smar-base-var-font-family,
      $font-size: 13px,
      $font-style: normal,
      $font-weight: 700,
      $line-height: 18px,
    ),
  label:
    createFontStylesProps(
      $font-family: $smar-medium-font-family,
      $font-style: normal,
      $font-weight: 500,
    ),
  font-medium:
    createFontStylesProps(
      $font-family: $smar-medium-font-family,
      $font-style: normal,
      $font-weight: 500,
    ),
  font-semi-bold:
    createFontStylesProps(
      $font-family: $smar-semi-bold-font-family,
      $font-style: normal,
      $font-weight: 600,
    ),
  font-bold:
    createFontStylesProps(
      $font-family: $smar-bold-font-family,
      $font-style: normal,
      $font-weight: 700,
    ),
  link:
    createLinkProps(
      $color: $cta-link,
      $color-hover: $cta-link,
      $font-family: $smar-base-font-family,
      $text-decoration-color: $cta-link,
      $font-size: 13px,
      $font-weight: 600,
      $line-height: 18px,
    ),
  badge-primary:
    createBadgeProps(
      $background-color: $button-secondary-active,
      $color: $dark-30,
    ),
  badge-secondary:
    createBadgeProps(
      $background-color: $button-secondary,
      $color: $dark-30,
    ),
  border: createBorderProps(),
  button-active-dark:
    createButtonProps(
      $background-color: $button-secondary-active,
      $background-color-active: $button-secondary-pressed,
      $background-color-disabled: $button-secondary-disabled,
      $background-color-hover: $button-secondary-active,
      $color: $dark-30,
      $color-disabled: $dark-10,
      $font-family: $smar-semi-bold-font-family,
      $font-weight: 600,
      $padding: 0 24px,
      $padding-with-icons: 0 12px,
    ),
  button-neutral:
    createButtonProps(
      $background-color: $button-secondary,
      $background-color-active: $button-secondary-pressed,
      $background-color-disabled: $button-secondary-disabled,
      $border: solid 1px $white,
      $color-disabled: $light-10,
      $color: $dark-30,
      $font-family: $smar-semi-bold-font-family,
      $font-weight: 600,
      $padding: 0 24px,
      $padding-with-icons: 0 12px,
    ),
  button-primary:
    createButtonProps(
      $background-color: $button-primary,
      $background-color-active: $button-primary-pressed,
      $background-color-disabled: $button-primary-disabled,
      $background-color-hover: $button-primary-hover,
      $color: $white,
      $color-disabled: $white,
      $font-family: $smar-bold-font-family,
      $font-weight: 700,
      $padding: 0 24px,
      $padding-with-icons: 0 12px,
    ),
  button-secondary:
    createButtonProps(
      $background-color: $button-secondary,
      $background-color-active: $button-secondary-pressed,
      $background-color-disabled: $button-secondary-disabled,
      $background-color-hover: $button-secondary-hover,
      $color: $dark-30,
      $color-disabled: $dark-10,
      $font-family: $smar-semi-bold-font-family,
      $font-weight: 600,
      $padding: 0 24px,
      $padding-with-icons: 0 12px,
    ),
  button-disabled:
    createButtonProps(
      $background-color: $button-secondary-disabled,
      $background-color-active: $button-secondary-pressed,
      $background-color-disabled: $button-secondary-disabled,
      $background-color-hover: $button-secondary-disabled,
      $color: $dark-30,
      $color-disabled: $dark-10,
      $font-family: $smar-semi-bold-font-family,
      $font-weight: 600,
      $padding: 0 24px,
      $padding-with-icons: 0 12px,
    ),
  button-success:
    createButtonProps(
      $background-color: $white,
      $border: 1px solid $button-upgrade,
      $color: $button-upgrade,
      $color-disabled: $button-upgrade-disabled,
      $font-family: $smar-semi-bold-font-family,
      $font-weight: 600,
      $padding: 0 24px,
      $padding-with-icons: 0 12px,
    ),
  colors:
    createColorProps(
      $primary: $primary,
      $text-primary: #4a4a4a,
      $text-dark: $dark-30,
      $text-semi-dark: $dark-20,
      $focus-light-20: $focus-light-20,
      $light-border: $light-10,
      $box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.16),
      $success-filter: brightness(0) saturate(100%) invert(30%) sepia(87%) saturate(1282%)
        hue-rotate(131deg) brightness(82%) contrast(101%),
      $cta-link: $primary,
      $cta-link-filter: brightness(0) saturate(100%) invert(26%) sepia(88%) saturate(1554%)
        hue-rotate(186deg) brightness(101%) contrast(93%),
      $notification-error: $danger-light-30,
      $notification-success: $success-light-30,
    ),
  icon-check-filled:
    createIconProps(
      $background-image: url(assets/check-filled.svg),
      $background-size: contain,
    ),
  delimiters:
    createDelimitersProps(
      $color: $light-10,
      $size: 1px,
    ),
  icon-alert-filled:
    createIconProps(
      $background-image: url(assets/alert-filled.svg),
      $background-size: contain,
    ),
  icon-info-filled:
    createIconProps(
      $background-image: url(assets/info-filled.svg),
      $background-size: contain,
    ),
  icon-content:
    createIconProps(
      $background-image: url(assets/content.svg),
    ),
  icon-arrow-right:
    createIconProps(
      $background-image: url(assets/lodestar-arrow-right.svg),
    ),
  icon-arrow-right-white:
    createIconProps(
      $background-image: url(assets/lodestar-arrow-right-white.svg),
    ),
  icon-attach-to-row:
    createIconProps(
      $background-image: url(assets/paperclip.svg),
      $background-size: contain,
    ),
  icon-attach-to-sheet:
    createIconProps(
      $background-image: url(assets/file.svg),
      $background-size: contain,
    ),
  icon-caret-down-primary:
    createIconProps(
      $background-image: url(assets/lodestar-caret-down-primary.svg),
    ),
  icon-caret-down-secondary:
    createIconProps(
      $background-image: url(assets/lodestar-caret-down-secondary.svg),
    ),
  icon-caret-down-white:
    createIconProps(
      $background-image: url(assets/lodestar-caret-down-white.svg),
    ),
  icon-caret-right-primary:
    createIconProps(
      $background-image: url(assets/lodestar-caret-right-primary.svg),
    ),
  icon-caret-right-secondary:
    createIconProps(
      $background-image: url(assets/lodestar-caret-right-secondary.svg),
    ),
  icon-caret-left-primary:
    createIconProps(
      $background-image: url(assets/caret-left-icon.svg),
    ),
  icon-caret-left-secondary:
    createIconProps(
      $background-image: url(assets/caret-left-icon.svg),
    ),
  icon-chevron-down:
    createIconProps(
      $background-image: url(assets/caret-down.svg),
    ),
  icon-chevron-right:
    createIconProps(
      $background-image: url(assets/caret-right.svg),
    ),
  icon-close:
    createIconProps(
      $background-image: url(assets/lodestar-icons/svg/core/medium/close.svg),
      $background-size: contain,
    ),
  icon-close-thin:
    createIconProps(
      $background-image: url(assets/close-thin.svg),
      $background-size: contain,
    ),
  icon-download:
    createIconProps(
      $background-image: url(assets/lodestar-download-arrow.svg),
    ),
  icon-drag-and-drop:
    createIconProps(
      $background-image: url(assets/drag-and-drop.svg),
    ),
  icon-filters-active:
    createIconProps(
      $background-image: url(assets/lodestar-filters-active.svg),
      $background-size: cover,
    ),
  icon-filters:
    createIconProps(
      $background-image: url(assets/lodestar-filters.svg),
      $background-size: cover,
    ),
  icon-more-options:
    createIconProps(
      $background-image: url(assets/lodestar-kebab.svg),
    ),
  icon-open-in-brandfolder:
    createIconProps(
      $background-image: url(assets/lodestar-box-arrow-up-right.svg),
    ),
  icon-pin:
    createIconProps(
      $background-image: url(assets/lodestar-pin.svg),
    ),
  icon-pin-active:
    createIconProps(
      $background-image: url(assets/lodestar-pin.svg),
    ),
  icon-search:
    createIconProps(
      $background-image: url(assets/lodestar-search.svg),
    ),
  icon-arrow-caret-left:
    createIconProps(
      $background-image: url(assets/lodestar-arrow-right.svg),
      $background-size: contain,
    ),
  icon-check-mark:
    createIconProps(
      $background-image: url(assets/lodestar-icons/svg/core/medium/check.svg),
      $background-size: contain,
    ),
  icon-labels-check-mark:
    createIconProps(
      $background-image: url(assets/lodestar-icons/svg/core/medium/check-blue.svg),
      $background-size: contain,
    ),
  icon-paperclip:
    createIconProps(
      $background-image: url('assets/lodestar-icons/svg/core/medium/attachments.svg'),
      $background-size: contain,
    ),
  loader:
    createLoaderProps(
      $background-image: url('https://cdn.bfldr.com/79ZJMSSN/at/mrt9hmcpspqztbtkc6xhs69/smar-preloader.gif?auto=webp&format=gif'),
      $background-size: contain,
      $height: 48px,
      $width: 48px,
    ),
  page-loader:
    createPageLoaderProps(
      $header-height: 66px,
      $min-height: 100vh,
      $org-header-height: 143px,
    ),
  select:
    createSelectProps(
      $background-color: $light-30,
      $background-hover: $light-20,
      $background-image: url(assets/lodestar-caret-down-primary.svg),
      $border-color: $light-30,
      $border-hover: $light-30,
      $border-radius-hover: 4px,
      $color: $dark-40,
      $font-family: $smar-base-font-family,
      $font-size: 13px,
      $font-weight: 500,
      $line-height: 18px,
      $placeholder-font-weight: 500,
      $placeholder-color: $dark-30,
    ),
  background-hover:
    createBackgroundHoverProps(
      $background-color: $light-20,
      $border-radius: 4px,
    ),
  spacing: createSpacingProps(),
  show-page: (
    container-title-size: 15px,
  ),
  notifications: (
    errorColor: $danger-light-30,
    successColor: $success-light-30,
  ),
);
