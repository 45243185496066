.image {
  &__container {
    display: block;
    height: 100%;
    position: relative;
    width: 100%;

    &--inner {
      position: relative;
      width: 100%;
    }
  }

  &__image {
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
  }
}