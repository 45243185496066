@use 'libs/core/src/lib/styles/themes/smartsheet-theme.scss' as *;

.icon-kebab-menu-container {
    .icon-kebab-menu-button {
        display: flex;
        font-size: 12px;
        border-radius: 4px;
        padding: 4px;
    }

    .icon-kebab-menu-button:hover {
        background-color: $button-secondary-hover;
    }

    .active {
        background-color: $button-secondary-pressed;
    }

    .active:hover {
        background-color: $button-secondary-pressed;
    }
}

.menu-card-popover {
    z-index: 10;
}
