@import 'video.js/dist/video-js.css';

.videojs-react-player-loader {
  background-color: unset;

  &:hover {
    outline-color: transparent;
  }

  .video-js {
    background-color: unset;
  }

  // override VideoJS inline border-radius
  .vjs-tech {
    border-radius: 8px;
  }

  .bc-player-default_default .vjs-poster {
    background-color: transparent;
  }

  .bc-player-default_default:hover .vjs-big-play-button {
    background-color: #4462c9;
  }

  .vjs-big-play-button .vjs-icon-placeholder:before,
  .video-js .vjs-play-control .vjs-icon-placeholder,
  .vjs-icon-play {
    font-family: VideoJS;
    font-style: normal;
    font-weight: 400;
  }

  .vjs-big-play-button .vjs-icon-placeholder:before,
  .vjs-icon-placeholder:before,
  .vjs-icon-play:before {
    content: '\f101';
  }

  .vjs-big-play-button {
    background-color: rgba(105, 135, 215, 0.6);
    border: none;
    border-radius: 50%;
    font-size: 3.5em;
    height: 1.5em;
    line-height: 1.5em;
    left: 50%;
    margin-left: -1em;
    margin-top: -1em;
    width: 1.5em;
    top: 50%;
    transition: border-color 0.4s, outline 0.4s, background-color 0.4s;

    &:hover {
      background-color: #4462c9;
    }
  }

  .vjs-big-play-button:focus {
    border-color: #fff;
    background-color: #73859f;
    background-color: rgba(115, 133, 159, 0.5);
    transition: all 0s;
  }

  .vjs-big-play-centered .vjs-big-play-button {
    left: 50%;
    margin-left: -1.5em;
    margin-top: -0.81666em;
    top: 50%;
  }

  .vjs-control-bar {
    background-color: #2b333f;
    background-color: rgba(105, 135, 215, 0.6);
    border-radius: 4px;
    bottom: 0;
    display: none;
    font-size: 10px;
    height: 3em;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    width: 100%;
  }

  .vjs-controls-disabled .vjs-big-play-button,
  .vjs-error .vjs-big-play-button,
  .vjs-has-started .vjs-big-play-button,
  .vjs-using-native-controls .vjs-big-play-button {
    display: none;
  }

  .vjs-dock-text {
    display: none;
  }

  .vjs-has-started .vjs-control-bar {
    display: flex;
    opacity: 1;
    transition: visibility 0.1s, opacity 0.1s;
    visibility: visible;
  }

  .vjs-has-started.vjs-paused.vjs-show-big-play-button-on-pause .vjs-big-play-button {
    display: block;
  }

  .vjs-play-progress {
    background: #4462c9;
  }
}
