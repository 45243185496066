#getting-started-container {
  height: 100vh;
  min-height: max-content;
  overflow-y: auto;
  overflow-x: hidden;
}

// page layout overrides
.theme-smartsheet {
  .page-container {
    .page-header {
      padding-top: 0;
    }

    .page-nav {
      padding-top: 0;
    }
  }
}
