.placement-details {
  background: #EEEEEE;
  padding: 2px 5px;
  width: auto;
  &:hover {
    background: #6097D2;
    color: white;
  }
}

.placement-tooltip-icon {
  background: #D8E4FA;
  width: auto;
  border-radius: 2px;
}
