@use 'libs/core/src/lib/styles/themes';

.lodestar-portal-container {
  display: block !important;

  .lodestar--focus-using-before {
    &:focus::before {
      z-index: 10;
    }
  }
}

.smar-upload-date-filter-container {
  @include themes.themify {
    .upload-date-filter-content {
      display: flex;
      flex-direction: column;
      gap: themes.themed('spacing', 'XXS');
    }

    .date-range-upload-container {
      display: flex;
      flex-direction: column;
      gap: themes.themed('spacing', 'M');
    }
  }
}
