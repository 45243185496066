@use 'libs/core/src/lib/styles/themes';

.form-container {
  @include themes.themify {
    padding: 0 themes.themed('spacing', 'L');

    .form-header {
      height: 74px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      padding-bottom: themes.themed('spacing', 'M');
    }

    .form-section {
      position: relative;
      border-top: 2px solid themes.themed('delimiters', 'color');

      &:last-child {
        border-bottom: 2px solid themes.themed('delimiters', 'color');
      }

      padding: themes.themed('spacing', 'L') 0;

      &:not(.expanded) {
        height: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .form-section-title,
      .form-subsection-title {
        width: 100%;
        margin-bottom: themes.themed('spacing', 'M');
      }

      .form-section-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        &:not(.expanded) {
          margin-bottom: 0;
        }

        .expand-icon {
          height: 18px;
          &:not(.expanded) {
            transform: rotate(-90deg);
          }
        }
      }

      .form-subsection {
        margin-bottom: themes.themed('spacing', 'L');

        &:last-child {
          margin-bottom: 0;
        }
      }

      .toggle-expand {
        position: absolute;
        top: 0;
        left: 0;
        height: 70px;
        width: 100%;
        cursor: pointer;
        z-index: 200;
      }
    }

    .form-section-header {
      margin-bottom: themes.themed('spacing', 'M');
    }

    .form-group {
      &.vertical {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      .form-row {
        &.vertical {
          display: flex;
          flex-direction: column;
          gap: themes.themed('spacing', 'XS');
        }
      }
    }

    .form-actions {
      $padding: themes.themed('spacing', 'L');

      background-color: white;
      display: flex;
      position: sticky;
      bottom: 0;
      z-index: 300;
      justify-content: space-between;
      align-items: center;
      border-top: 2px solid themes.themed('delimiters', 'color');
      border-bottom: 2px solid themes.themed('delimiters', 'color');
      padding: 0 $padding;
      margin: 0 (-$padding);
      height: 90px;
    }
  }
}
