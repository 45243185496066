@use 'libs/core/src/lib/styles/themes';

.lodestar--focus-using-before {
  [type='text']:focus {
    box-shadow: none !important;
  }
}

.smar-select-placeholder {
  @include themes.themify {
    font-family: themes.themed('select', 'font-family');
    font-style: normal;
    font-weight: themes.themed('select', 'placeholder-font-weight');
    font-size: themes.themed('select', 'font-size');
    line-height: themes.themed('select', 'line-height');
    color: themes.themed('select', 'placeholder-color');
  }
}

.smar-select-option {
  @include themes.themify {
    font-family: themes.themed('select', 'font-family');
    font-style: normal;
    font-weight: themes.themed('select', 'font-weight');
    font-size: themes.themed('select', 'font-size');
    line-height: themes.themed('select', 'line-height');
    color: themes.themed('select', 'color');
  }
}
