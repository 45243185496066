@use 'libs/core/src/lib/styles/themes';

#smar-advanced-filters {
  @include themes.themify {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 2;

    .bf-hr {
      margin: themes.themed('spacing', 'L') 0;
    }

    .list-item {
      display: flex;
      gap: themes.themed('spacing', 'XS');
      align-items: center;
    }

    .checkbox-list {
      margin-top: themes.themed('spacing', 'L');
      max-width: 250px;
    }

    .apply-btn {
      width: 135px;
    }
  }
}
