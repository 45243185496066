@use 'libs/core/src/lib/styles/themes' as *;

.panel-header-container {
  z-index: 2;
  transform: translate3d(0,0,0);
  
  @include themify {
    background-color: themed('colors', 'white');
    padding-top: themed('spacing', 'L');
    padding-right: themed('spacing', 'L');
    padding-left: themed('spacing', 'L');
    padding-bottom: themed('spacing', 'M');
  }
}

.brandfolder-header-text {
  width: 136px;
}

#smar-header-close {
  background: white;
  margin-top: 0px;
  padding: 0px 0px 7px 0px;
}
