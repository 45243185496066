@use 'libs/core/src/lib/styles/themes';
@use './libs/core/src/lib/styles/utilities/media-queries';

/* SMAR spacing units - temporary, need to make it part of theming system */
$spacing-unit-l: 24px;
$spacing-unit-xl: 32px;
$spacing-unit-xxl: 48px;

.zero-state-container {
  width: 100%;
  min-height: 528px;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 48px;
  padding-right: 48px;

  .bf-smar-logo {
    height: 50px;
    max-width: 160px;
    margin-top: 48px;
    margin-bottom: $spacing-unit-xxl;
  }

  .zero-state-content-container {
    border-radius: 4px;
    /* CAN REMOVE BELLOW AFTER VIDEO PLACED*/
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 64px;
    max-width: 456px;
    width: 100%;

    .connect-to-bf-btn-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .connect-to-bf-btn {
        height: 50px;
        max-width: 250px;
        padding: 12px 24px !important;
        font-size: 14px;
        border-radius: 4px;
        margin-bottom: $spacing-unit-l;
      }
    }

    .create-bf-cta-wrapper {
      height: 61px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .click-here-span {
        white-space: nowrap;
      }

      .click-here-text {
        display: inline-block;
      }
    }

    .divider-line {
      height: 2px;
      background: #e0e0e0;
      border-radius: 0px;
      margin-top: $spacing-unit-xl;
      max-width: 456px;
      margin-bottom: $spacing-unit-xl;
      width: 100%;
    }

    @include themes.themify {
      .zero-state-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: themes.themed('spacing', 'S');

        .brandfolder-description {
          max-width: 380px;
          line-height: 18px;
          margin-bottom: $spacing-unit-xl;

          .description-header{
            font-family: Inter;
            font-style: normal;
            font-weight: 700;
            font-size: 13px;
            line-height: 18px;
            color: #444444;
          }
        }

        .api-key-login-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          max-width: 456px;
          width: 100%;
        }

        .api-key-disclosure-panel {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          max-width: 456px;
          width: 100%;
        }

        .api-key-input-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          max-width: 456px;
        }

        .api-key-label {
          display: flex;
          justify-content: space-between;
          align-items: center;
          max-width: 380px;
          margin-top: 40px;
          width: 100%;
        }

        #brandfolder-api-key {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          max-width: 380px;
        }
      }
    }

    .sign-in-btn {
      margin-top: 16px;
      height: 32px !important;
      max-width: 380px;
      width: 100%;
    }
  }

  .click-here-link,
  .sign-in-toggle {
    color: #3576ba;
  }

  .sign-in-toggle{
    margin-top: 40px;
    max-width: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}
@media only screen and (min-width: 920px) {
  .zero-state-container {
    justify-content: center;
    padding-left: 24px;
    padding-right: 24px;
    .zero-state-content-container {
      max-width: 900px;
      flex-direction: row-reverse;
      align-items: center;
    }

    .divider-line {
      display: none;
    }
  }
}
